type TransactionStatusType = {
  [key: number]: string
  TRANSACTION_STATUS_COMPLETED: number
  TRANSACTION_STATUS_EDITED: number
  TRANSACTION_STATUS_CRETED: number
  TRANSACTION_STATUS_DEBITED: number
  TRANSACTION_STATUS_DELETE: number
  TRANSACTION_STATUS_SETTLED: number

}

type MessageStatusType = {
  [key: number]: string
  MESSAGE_STATUS_DELIVERED: number
  MESSAGE_STATUS_READ: number
  MESSAGE_STATUS_CALL: number
  MESSAGE_STATUS_VERIFIED: number
  MESSAGE_STATUS_VERIFIED_FAILED: number
}

export const constants = {
  PROJECT_NAME: process.env.REACT_APP_TITLE,
  COMMISSION_API: process.env.REACT_APP_COMMISSION_API || `http://192.168.1.24:3002/getcommission`,

  TRANSACTION_STATUS: {
    0: 'Transaction Completed',
    1: 'Transaction Edited',
    2: 'Credit Party Final',
    3: 'Debit Party Final',
    4: 'Delete',
    5: 'Transaction Settled',

    TRANSACTION_STATUS_COMPLETED: 0,
    TRANSACTION_STATUS_EDITED: 1,
    TRANSACTION_STATUS_CRETED: 2,
    TRANSACTION_STATUS_DEBITED: 3,
    TRANSACTION_STATUS_DELETE: 4,
    TRANSACTION_STATUS_SETTLED: 5,
  } as TransactionStatusType,

  TRANSACTION_STATUS_DROPDOWN: [
    { label: 'Transaction Completed', value: 0 },
    { label: 'Transaction Edited', value: 1 },
    { label: 'Debit Party Final', value: 2 },
    { label: 'Credit Party Final', value: 3 },
    // { label: 'Delete', value: 4 },
    { label: 'Transaction Settled', value: 5 },

  ],

  MESSAGE_STATUS: {
    0: 'Delivered',
    1: 'Read',
    2: 'Call',
    3: "Verified",
    4: "Verified Failed",
    MESSAGE_STATUS_DELIVERED: 0,
    MESSAGE_STATUS_READ: 1,
    MESSAGE_STATUS_CALL: 2,
    MESSAGE_STATUS_VERIFIED: 3,
    MESSAGE_STATUS_VERIFIED_FAILED: 4,
  } as MessageStatusType,

  MESSAGE_STATUS_DROPDOWN: [
    { label: 'Delivered', value: 0 },
    { label: 'Read', value: 1 },
    { label: 'Call', value: 2 },
    { label: 'Verified', value: 3 },
    { label: 'Verified Failed', value: 4 }
  ],

  JV_DROPDOWN: [
    { label: 'YES', value: 1 },
    { label: 'NO', value: 0 },
  ],

  DATE_FORMAT: {
    YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd hh:mm:ss',
    DD_MM_YYYY_HH_MM_A: 'dd-MM-yyyy hh:mm a',
    DD_MM_YYYY: 'dd-MM-yyyy',
  },

  
  formatAmount(amount: any): any {
    if (amount == '-') return  amount = '-'
    
    return new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 0,
    }).format(amount);
  }
}
