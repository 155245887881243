import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {constants} from '../../../../../utils/const'
import {configuration} from '../../../../../configuration'

const API_URL = configuration.apiUrl
const COMMISSION_API = `${API_URL}/messages/getCommission`
const Message_URL = `${API_URL}/messages`
const UPDATE_Message_URL = `${API_URL}/messages/updateLable`
const UPDATE_Message = `${API_URL}/messages`
const Message_URL_NEW = `${API_URL}/messages`
const Message_URL_NEW_Sep = `${API_URL}/messages/addMessage`
const MESSAGE_LIST_URL = `${API_URL}/messages/getList`
const Customer_URL = `${API_URL}/customer`
const PARTY_URL = `${API_URL}/parties/onlyPartys`
const getCustomers = (query: string): Promise<any> => {
  return axios
    .get(`${Customer_URL}?${query}&sort=customer_name&order=ASC`)
    .then((d: AxiosResponse<any>) => d.data)
}
const getParty = (query: string): Promise<any> => {
  return axios.get(`${PARTY_URL}?sort=name&order=ASC`).then((d: AxiosResponse<any>) => d.data)
}
const getMessage = (reqData: any): Promise<any> => {
  return axios
    .get(`${MESSAGE_LIST_URL}?${reqData}`, reqData)
    .then((d: AxiosResponse<any>) => d?.data)
}

const getMessageById = (id: ID): Promise<any> => {
  return axios
    .get(`${Message_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const addMessage = (message: any): Promise<any> => {
  return axios
    .post(Message_URL_NEW, message)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const addNewMessage = (message: any): Promise<any> => {
  return axios
    .post(Message_URL_NEW_Sep, message)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateMessage = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${UPDATE_Message_URL}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const updateMessageModule = (data: any, id: any): Promise<any> => {
  return axios
    .patch(`${UPDATE_Message}/${id}`, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const approveMessage = (id: string): Promise<any> => {
  return axios
    .get(`${Message_URL}/approve/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteMessage = (id: string): Promise<void> => {
  return axios.delete(`${Message_URL}/${id}`).then((response: AxiosResponse<any>) => response.data)
}

const deleteSelectedMessage = (MessageIds: Array<ID>): Promise<void> => {
  const requests = MessageIds.map((id) => axios.delete(`${Message_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const messageFilterApi = (search: any): Promise<any> => {
  return axios
    .post(`${MESSAGE_LIST_URL}`, search)
    .then((d: AxiosResponse<any>) => d?.data)
    .then((d: AxiosResponse<any>) => d)
}

const getCommissionApi = (data: any): Promise<any> => {
  return axios.post(COMMISSION_API, data).then((d: AxiosResponse<any>) => d?.data)
}

export {
  getCustomers,
  getMessage,
  deleteMessage,
  deleteSelectedMessage,
  getMessageById,
  addMessage,
  addNewMessage,
  updateMessageModule,
  updateMessage,
  messageFilterApi,
  getCommissionApi,
  approveMessage,
  getParty
}
